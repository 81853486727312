<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Контактные реквизиты {{ form.name }}
    </h4>
    <div>
      <b-row>
        <b-col md="6">
          <b-form-group label="Название">
            <b-form-input
              v-model="form.name"
              type="text"
              autocomplete="false"
              autofocus
              required
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Роль">
            <b-form-input
              v-model="form.role"
              type="text"
              autocomplete="false"
              autofocus
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="ФИО">
            <b-form-input
              v-model="form.fullname"
              type="text"
              autocomplete="false"
              autofocus
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Должность">
            <b-form-input
              v-model="form.position"
              type="text"
              autocomplete="false"
              autofocus
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Email">
            <b-form-input
              v-model="form.email"
              type="email"
              name="email"
              autocomplete="false"
              autofocus
              required
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Телефон">
            <b-form-input
              id="phone1"
              v-model="form.phone"
              v-mask="'+7 (999) 999-99-99'"
              type="text"
              autocomplete="false"
              autofocus
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Мобильный телефон">
            <b-form-input
              id="phone2"
              v-model="form.mobilePhone"
              v-mask="'+7 (999) 999-99-99'"
              type="text"
              autocomplete="false"
              autofocus
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="ICQ">
            <b-form-input
              v-model="form.icq"
              type="text"
              autocomplete="false"
              autofocus
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Skype">
            <b-form-input
              v-model="form.skype"
              type="text"
              autocomplete="false"
              autofocus
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-checkbox
              v-model="form.isDefault"
              autocomplete="false"
              autofocus
              :disabled="true"
            >
              Реквизит по умолчанию
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          v-if="$store.getters.isAdmin"
          variant="primary"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
          :href="`/contact-requisites/id${contactId}`"
        >
          Редактировать
        </b-button>
        <b-button
          v-if="$store.getters.isAdmin"
          type="button"
          variant="danger"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="confirmDeleteRequisite"
        >
          Удалить
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {contactRequisitesRead, contactRequisitesDelete} from '../../services/api';

export default {
  name: 'ContactForm',
  mixins: [notifications],
  props: ['contactId'],
  data() {
    return {
      loading: false,
      form: {
        name: '',
        role: '',
        fullname: '',
        position: '',
        email: '',
        phone: '',
        mobilePhone: '',
        icq: '',
        skype: '',
        isDefault: false,
        customer: {
          id: null,
        },
      },
      contactRequisite: false,
    };
  },
  mounted() {
    this.form.customer.id = this.$store.state.user.ownerId;
    if (this.contactId) {
      this.contactRequisitesRead();
    }
  },
  methods: {
    async contactRequisitesRead() {
      this.loading = true;
      const response = await contactRequisitesRead(this.contactId);
      if (response && response.status === 200) {
        this.form = Object.assign({}, response.data);
      }
      this.loading = false;
    },
    confirmDeleteRequisite() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить контактные реквизиты?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteRequisite();
          }
        });
    },
    async deleteRequisite() {
      this.loading = true;
      const response = await contactRequisitesDelete(this.contactId);
      if (response && response.status === 200) {
        this.showSuccess('Контактные реквизиты удалены');
        this.$router.push({path: '/contact-requisites'});
      }
      this.loading = false;
    },
  },
};
</script>

